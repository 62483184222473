
div#suggestion-search-container.nav-search__search-container._-7m-2d080hJg3V1G1WqY-.sc-iwsKbI.hAUoSP {
  -webkit-box-align: center;
  -webkit-box-flex: 1;
  align-items: center;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  flex-grow: 1;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  left: 0;
  letter-spacing: .5px;
  line-height: 24px;
  margin: 1.5rem 0 0 1.5vw;
  padding: 0;
  min-height: 2.25rem;
  opacity: 1;
  order: 3;
  padding: 0;
  pointer-events: auto;
  position: relative;
  top: 0;
  transform: translateY(0);
  transition: none 0s;
  visibility: visible;
  width: 94vw;
  z-index: 1;
}

form#nav-search-form._3tGWq6W2TYPbP48TZtGEaB.imdb-header__search-form.sc-dnqmqq.ibWJpR {
  -webkit-box-align: center;
  -webkit-box-flex: 1;
  align-items: center;
  background: #FFFFFF;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  flex-grow: 1;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  transition: border .2s, background-color .2s, box-shadow .2s;
}

div.search-category-selector.sc-htoDjs.jAJuqP {
  box-sizing: border-box;
}

div.sc-gzVnrw.sVyDb.navbar__flyout--breakpoint-m.navbar__flyout--positionLeft {
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  position: relative;
}

input#navbar-search-category-select.ipc-menu__focused-state {
  appearance: none;
  border-style: none;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  display: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0;
  height: 0;
  line-height: 24px;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0;
}

div.ipc-menu.mdc-menu.ipc-menu--not-initialized.ipc-menu--on-baseAlt.ipc-menu--anchored.ipc-menu--with-checkbox.ipc-menu--expand-from-top-left.navbar__flyout--menu {
  background: #1F1F1F;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 1px 5px 0;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-block;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  left: 0;
  letter-spacing: .5px;
  line-height: 24px;
  margin: .25rem 0 0;
  max-height: calc(100vh - 32px);
  max-width: calc(100vw - 32px);
  min-width: 112px;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: .5rem 0;
  position: absolute;
  right: auto;
  top: 100%;
  transform: scale(.8);
  transform-origin: left top;
  visibility: hidden;
  white-space: nowrap;
  will-change: transform, opacity;
  z-index: 4;
}

div.ipc-menu__items.mdc-menu__items {
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: scale(1);
  white-space: nowrap;
  will-change: transform;
}

span#navbar-search-category-select-contents {
  box-sizing: border-box;
}

ul.ipc-list._2mcNnzNh_17zh5IV1Mukpr.ipc-list--baseAlt {
  -webkit-font-smoothing: auto;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .03125em;
  line-height: 1.5rem;
  list-style: none;
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none #FFFFFF solid;
  text-transform: none;
  white-space: nowrap;
  width: 100%;
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r._1VKSPp5LHTu0I_RQCOsBDd {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  color: #F5C518;
  cursor: pointer;
  display: flex;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 3rem;
  justify-content: flex-start;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  padding: 0 1rem;
  position: relative;
  text-decoration: none;
  transition: color .15s;
  user-select: none;
  white-space: nowrap;
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r._1VKSPp5LHTu0I_RQCOsBDd:hover {
  background: rgba(255, 255, 255, 0.12);
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r._1VKSPp5LHTu0I_RQCOsBDd:active {
  background: rgba(255, 255, 255, 0.32);
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r._1VKSPp5LHTu0I_RQCOsBDd:focus:focus {
  outline-offset: 1px;
}

span.ipc-list-item__text {
  box-sizing: border-box;
  color: #F5C518;
  cursor: pointer;
  display: block;
  flex-grow: 1;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--search._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #F5C518;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: 1;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--search._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

path {
  box-sizing: border-box;
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 3rem;
  justify-content: flex-start;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  padding: 0 1rem;
  position: relative;
  text-decoration: none;
  transition: color .15s;
  user-select: none;
  white-space: nowrap;
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r:hover {
  background: rgba(255, 255, 255, 0.12);
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r:active {
  background: rgba(255, 255, 255, 0.32);
}

a.ipc-list__item._1xTnkpad08eEWkqotpqi4r:focus:focus {
  outline-offset: 1px;
}

svg.ipc-icon.ipc-icon--movie._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: .5;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--movie._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

svg.ipc-icon.ipc-icon--television._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: .5;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--television._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

svg.ipc-icon.ipc-icon--people._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: .5;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--people._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

svg.ipc-icon.ipc-icon--business._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: .5;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--business._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

svg.ipc-icon.ipc-icon--label._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: .5;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--label._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

li.ipc-list-divider {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 0;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin: 0;
  text-align: left;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--find-in-page._37fcCRzERfyeXeLFPsjHdY {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  margin-right: .75rem;
  opacity: .5;
  transition: opacity .2s;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--find-in-page._37fcCRzERfyeXeLFPsjHdY:not(:root) {
  overflow: hidden;
}

span.ipc-list-item__icon.ipc-list-item__icon--post {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  opacity: .54;
  padding-left: .75rem;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--chevron-right {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  list-style: none;
  vertical-align: middle;
  white-space: nowrap;
}

svg.ipc-icon.ipc-icon--chevron-right:not(:root) {
  overflow: hidden;
}

div.nav-search__search-input-container.sc-bZQynM.jIbbnv {
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 24px;
  padding-right: 3.5rem;
  width: 100%;
}

div.react-autosuggest__container {
  box-sizing: border-box;
}

input#suggestion-search.imdb-header-search__input.GVtrp0cCs2HZCo7E2L5UU.react-autosuggest__input {
  -webkit-box-flex: 1;
  -webkit-font-smoothing: auto;
  appearance: none;
  background-color: transparent;
  border-style: none;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
  flex-grow: 1;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: .01786em;
  line-height: 1.25rem;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: .375em 0 .375rem .5rem;
  text-decoration: none #FFFFFF solid;
  text-transform: none;
  width: 100%;
}

button#suggestion-search-button.nav-search__search-submit._2wo2z_hfPCHWGjZvUGi_f1 {
  appearance: none;
  background: #FFFFFF;
  border-style: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  min-width: 2rem;
  overflow: visible;
  padding: 0;
  position: absolute;
  right: .35rem;
  text-align: center;
  text-transform: none;
  top: .35rem;
  transition: all .2s;
}

button#suggestion-search-button.nav-search__search-submit._2wo2z_hfPCHWGjZvUGi_f1:focus {
  outline-offset: 1px;
}

svg.ipc-icon.ipc-icon--magnify {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
}

svg.ipc-icon.ipc-icon--magnify:not(:root) {
  overflow: hidden;
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase {
  appearance: none;
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 0;
  margin: .25rem;
  padding: .75rem;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
  transform: scale(.5);
  transition: transform .2s .1s;
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase:before {
  background-color: currentcolor;
  border-radius: 50%;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  transform-origin: center center;
  transition: transform .2s cubic-bezier(.175, 0.885, 0.32, 1), opacity .2s cubic-bezier(.175, 0.885, 0.32, 1);
  width: 100%;
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase:after {
  background-color: currentcolor;
  border-radius: 50%;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0);
  transform-origin: center center;
  transition: transform .2s cubic-bezier(.175, 0.885, 0.32, 1), opacity .2s cubic-bezier(.175, 0.885, 0.32, 1);
  width: 100%;
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase:active:after {
  opacity: 1;
  transform: scale(1);
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase:disabled {
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase:hover:before {
  opacity: 1;
}

label#imdbHeader-searchClose.ipc-icon-button.imdb-header-search__state-closer.ipc-icon-button--baseAlt.ipc-icon-button--onBase:focus {
  outline-offset: 1px;
}

svg.ipc-icon.ipc-icon--clear {
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  line-height: 0;
  vertical-align: middle;
}

svg.ipc-icon.ipc-icon--clear:not(:root) {
  overflow: hidden;
}
