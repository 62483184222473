body {
  background: #000;
}

.* {
  font-family: Roboto, SourceSansProLight, Helvetica, Arial, Verdana, sans-serif;
}

.app-wrapper {
  flex-basis: 50%;
  padding-bottom: 6rem;
  background-image: linear-gradient( to right, rgb(0 0 0) 0, rgb(19 19 19) 100% );
}

.categoryWrapper {
  background: #121212;
}

.movie-carousel {
  min-height: 45vh;
  scroll-snap-type: x mandatory;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}

.movieWrapper {
  scroll-snap-align: center;
}

.movieWrapper:first-child {
  margin-left: 3em
}

.movieWrapper:last-child {
  padding-right: 3em
}

.posterWrapper {
  position: relative;
  margin-right: 1em;
  margin-left: 1em;
  width: 200px;
  height: 320px;
}

.poster {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0.85);
  transition: all ease-in-out 300ms;
}

.posterArea {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.posterTitle {
  margin-left: 1rem;
  margin-top: 1.5rem;
  padding-right: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  color: #FFF;
  line-height: normal;
}

.selectedPoster {
  transform: scale(1);
}

.categoryTitle {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding-left: 1em !important;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.categoryTitle::before {
  background: #f5c518;
  border-radius: 4px;
  content: '';
  height: 1em;
  width: 4px;
  margin-right: .3em;
}

.mainTitle {
  margin-top: 1em;
  margin-bottom: .5em;
  color: #F5C518;
  font-weight: 600;
  font-size: 34px;
  padding-left: .5em;
}

.subTitle {
  margin-top: 0.2em;
  margin-bottom: 2em;
  font-size: 23px;
  color: #FFFFFFB3;
  padding-left: .8em;
  padding-right: .8em;
}

.amazonLogo {
  width: 150px;
  margin-top: 4em;
  margin-bottom: 1em;
}

.center {
  text-align: center;
}

.copywrite {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.imdbHeader {
  background: #121212;
  padding: 1em 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 0 1em;
  flex-grow: 1;
}

.headerLink {
  color: #FFF;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  margin: 0 .5em;
  padding: .7em .9em;
  border-radius: 4px;
}

@media(max-width:550px) {
  .hideMobile{display:none;}
}

@media(max-width:350px) {
  .hideSmallMobile{display:none;}
}


.headerLink:hover {
  background: #2e2e2e;
}
